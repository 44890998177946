import DataTransferUtil from '@/utils/data-transfer.util';
import { PRODUCT_CATEGORY, PRODUCT_PAD_VERSION_FLAG_TYPE, PRODUCT_SALE_TYPE } from './enum';

export const DEVICE_COLUMNS = [
  // {
  //   title: '设备编号',
  //   key: 'product_no',
  //   minWidth: 150,
  //   fixed: 'left',
  // },
  {
    title: '生产编号',
    key: 'produce_no',
    minWidth: 180,
    fixed: 'left',
  },
  {
    title: '设备名',
    key: 'name',
    minWidth: 150,
    fixed: 'left',
  },
  {
    title: 'mac地址',
    key: 'mac_address',
    width: 200,
  },
  {
    title: '生产批号',
    key: 'batch_no',
    width: 200,
  },
  {
    title: '型号',
    key: 'mes_product_config_code',
    width: 200,
  },
  {
    title: '硬件版本',
    key: 'hardware_version',
    width: 100,
  },
  {
    title: '归属渠道',
    key: 'channel_name',
    minWidth: 150,
  },
  {
    title: '归属门店',
    key: 'store_name',
    minWidth: 150,
  },
  {
    title: '销售类型',
    key: 'saleType',
    slot: 'saleType',
    minWidth: 100,
  },
  {
    title: '运行状态',
    key: 'status',
    slot: 'status',
    minWidth: 100,
  },
  {
    title: '激活状态',
    key: 'active_status',
    slot: 'activeStatus',
    minWidth: 100,
  },
  {
    title: '设备类型',
    key: 'product_category',
    slot: 'productCategory',
    minWidth: 150,
  },
  {
    title: '激活方式',
    slot: 'activeMethod',
    key: 'active_method',
    minWidth: 100,
  },
  {
    title: '激活时间',
    key: 'active_time',
    minWidth: 180,
  },
  {
    title: '设备SN',
    key: 'sn_no',
    width: 200,
  },
  {
    title: '学习授权状态',
    key: 'third_license_status',
    slot: 'thirdLicenseStatus',
    width: 150,
  },
  {
    title: '操作',
    slot: 'action',
    minWidth: 100,
    maxWidth: 320,
    align: 'center',
    fixed: 'right',
  },
  {
    title: '训练模块',
    slot: 'trainFlag',
    key: 'tt_ppv_type',
    minWidth: 100,
  },
  {
    title: '检查模块',
    slot: 'examFlag',
    key: 'te_ppv_type',
    minWidth: 100,
  },
];

export const ALLOWED_PERMISSION = {
  EDIT: 'product:p-v3:edit',
  DISTRIBUTE: 'product:p-v3:distribute',
  RETURN: 'product:p-v3:sendback',
  DISTRIBUTE_HISTORY: 'product:p-v3:distflow',
  REMARK: 'product:p-v3:remarksEdit',
  SALE_TYPE_EDIT: 'product:p-v3:saleTypeEdit',
};

export const DEVICE_ACTION = {
  CREATE: 'product:p-v3:add',
  ...ALLOWED_PERMISSION,
};

export const DEVICE_ACTION_TEXT = {
  [DEVICE_ACTION.CREATE]: '新建设备',
  [DEVICE_ACTION.EDIT]: '编辑设备',
  [DEVICE_ACTION.DISTRIBUTE]: '分配设备',
  [DEVICE_ACTION.DISTRIBUTE_HISTORY]: '分配流',
  [DEVICE_ACTION.RETURN]: '退库',
  [DEVICE_ACTION.SALE_TYPE_EDIT]: '销售类型',
  [DEVICE_ACTION.REMARK]: '备注',
};

export const STATUS = {
  ALL: 'all',
  ENABLE: 1,
  DISABLE: 0,
};

export const BASIC_STATUS_TEXT = {
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const STATUS_TEXT = {
  [STATUS.ALL]: '全部',
  ...BASIC_STATUS_TEXT,
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const HARDVERSION = {
  '1_0': '1.0',
  '1_1': '1.1',
  '1_2': '1.2',
};

export const BASIC_HARDVERSION_TEXT = {
  [HARDVERSION['1_0']]: '1.0 无棱镜',
  [HARDVERSION['1_1']]: '1.1 有棱镜(1.5倍)',
  [HARDVERSION['1_2']]: '1.2 有棱镜',
};

// value值为字符串
export const BASIC_HARDVERSION_OPTIONS = DataTransferUtil.strEnumTransfer2Options(
  BASIC_HARDVERSION_TEXT,
);

export const BASIC_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(BASIC_STATUS_TEXT);

export const STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(STATUS_TEXT);

export const PURPOSE = {
  BUSINESS: 'sy',
  SCIENTIFIC_RESEARCH: 'ky',
  TEST: 'cs',
  OTHER: 'qt',
};

export const PURPOSE_TEXT = {
  [PURPOSE.TEST]: '测试',
  [PURPOSE.SCIENTIFIC_RESEARCH]: '科研',
  [PURPOSE.BUSINESS]: '商业',
  [PURPOSE.OTHER]: '其他',
};

export const PURPOSE_OPTIONS = DataTransferUtil.enumTransfer2Options(PURPOSE_TEXT);

export const ACTIVE_STATUS = {
  ALL: 'all',
  UN_ACTIVE: 0,
  ACTIVE: 1,
  RETURN: 2,
  MD: 'store',
  GR: 'customer',
};

export const ACTIVE_STATUS_TEXT = {
  [ACTIVE_STATUS.ALL]: '全部',
  [ACTIVE_STATUS.UN_ACTIVE]: '未激活',
  [ACTIVE_STATUS.ACTIVE]: '已激活',
  [ACTIVE_STATUS.RETURN]: '已退库',
};
export const ACTIVE_METH_TEXT = {
  [ACTIVE_STATUS.MD]: '门店',
  [ACTIVE_STATUS.GR]: '消费者',
};

export const PRODUCT_CATEGORY_TEXT = {
  [PRODUCT_CATEGORY.HOME]: '家庭类型',
  [PRODUCT_CATEGORY.STORE]: '门店类型',
  [PRODUCT_CATEGORY.ALL]: '家庭门店均可',
};

export const PRODUCT_PAD_VERSION_FLAG_TYPE_TEXT = {
  [PRODUCT_PAD_VERSION_FLAG_TYPE.CLOSE]: '关闭',
  [PRODUCT_PAD_VERSION_FLAG_TYPE.OPEN]: '开通',
  [PRODUCT_PAD_VERSION_FLAG_TYPE.EXPIRE]: '过期',
  [PRODUCT_PAD_VERSION_FLAG_TYPE.PERMANENT]: '永久可用',
};

export const ACTIVE_STATUS_OPTIONS = DataTransferUtil.enumTransfer2Options(ACTIVE_STATUS_TEXT);

export const TIPS = {
  NAME: '请输入设备名称',
  HARDWARE_VERSION: '请输入硬件版本',
  MACADDRESS: '请输入mac地址',
  PURPOSE: '请选择用途',
  STATUS: '请选择运行状态',
  CHANNEL: '请选择分配渠道',
  STORE: '请选择分配门店',
  PRODUCT: '请输入设备编号',
  PRODUCE: '请输入生产编号',
  REMARK: '请输入备注',
  PRODUCT_CATEGORY: '请选择设备类型',
  SN_NO: '请输入设备SN',
};

export const PRODUCT_PAD_VERSION_FLAG_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(
  PRODUCT_PAD_VERSION_FLAG_TYPE_TEXT,
);

export const PRODUCT_SALE_TYPE_TEXT = {
  [PRODUCT_SALE_TYPE.FOR_ALL]: '出售',
  [PRODUCT_SALE_TYPE.FOR_LEASE]: '租赁',
  [PRODUCT_SALE_TYPE.FOR_TRY_OUT]: '试用',
};

export const PRODUCT_SALE_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(
  PRODUCT_SALE_TYPE_TEXT,
);
