import axios from '@/plugins/axios';
import { ACTIVE_STATUS, STATUS } from './constants';
import { PRODUCT_TYPE } from './enum';

class DeviceManageApi {
  static getDevices(
    page = 1,
    limit = 10,
    channelId = NaN,
    storeId = NaN,
    productNo = '',
    produceNo = '',
    status = STATUS.ALL,
    activeStatus = ACTIVE_STATUS.ALL,
    macAddress = '',
    name = '',
    productType = PRODUCT_TYPE.PV3_QN,
    productCategory = NaN,
    activeMethod = '',
    hardwareVersion = '',
    thirdLicenseStatus = NaN,
    snNo = '',
    batchNo = '',
    mesProductConfigId = NaN,
    trainFlagType = NaN,
    examFlagType = NaN,
    saleType = NaN,
  ) {
    return axios({
      url: '/api/product/pv3/page',
      data: {
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        product_no: productNo,
        produce_no: produceNo,
        status,
        active_status: activeStatus,
        mac_address: macAddress,
        name,
        product_type: productType,
        product_category: productCategory,
        active_method: activeMethod,
        hardware_version: hardwareVersion,
        third_license_status: thirdLicenseStatus,
        sn_no: snNo,
        batch_no: batchNo,
        mes_product_config_id: mesProductConfigId,
        train_flag_type: trainFlagType,
        exam_flag_type: examFlagType,
        sale_type: saleType,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询设备列表',
        },
      },
    });
  }

  static createDevice(
    name = '',
    hardwareVersion = '',
    productNo = '',
    produceNo = '',
    purpose = '',
    status = STATUS.DISABLE,
    macAddress = '',
    productType = PRODUCT_TYPE.PV3_QN,
    productCategory = NaN,
    snNo = '',
    batchNo = '',
    mesProductConfigId = NaN,
  ) {
    return axios({
      url: '/api/product/pv3/add',
      data: {
        name,
        hardware_version: hardwareVersion,
        product_no: productNo,
        produce_no: produceNo,
        purpose,
        status,
        mac_address: macAddress,
        product_type: productType,
        product_category: productCategory,
        sn_no: snNo,
        batch_no: batchNo,
        mes_product_config_id: mesProductConfigId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '创建设备',
        },
      },
    });
  }

  static editDevice(
    id = NaN,
    name = '',
    hardwareVersion = '',
    productNo = '',
    produceNo = '',
    purpose = '',
    status = STATUS.DISABLE,
    macAddress = '',
    productCategory = NaN,
    snNo = '',
    batchNo = '',
    mesProductConfigId = NaN,
  ) {
    return axios({
      url: '/api/product/pv3/edit',
      data: {
        id,
        name,
        hardware_version: hardwareVersion,
        product_no: productNo,
        produce_no: produceNo,
        purpose,
        status,
        mac_address: macAddress,
        product_category: productCategory,
        sn_no: snNo,
        batch_no: batchNo,
        mes_product_config_id: mesProductConfigId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '编辑设备',
        },
      },
    });
  }

  static distributeDevice(
    id = NaN,
    channelId = NaN,
    storeId = NaN,
  ) {
    return axios({
      url: `/api/product/pv3/${id}/distribute`,
      data: {
        channel_id: channelId,
        store_id: storeId,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '分配设备',
        },
      },
    });
  }

  static returnDevice(
    id = NaN,
  ) {
    return axios({
      url: `/api/product/pv3/${id}/sendback`,
      method: 'post',
      headers: {
        meta: {
          desc: '退回设备',
        },
      },
    });
  }

  static remarkDevice(
    id = NaN,
    remarks = NaN,
  ) {
    return axios({
      url: '/api/product/pv3/remarks_edit',
      data: {
        id,
        remarks,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '设备备注',
        },
      },
    });
  }

  static saleTypeDevice(
    id = NaN,
    saleType = NaN,
  ) {
    return axios({
      url: '/api/product/pv3/sale_type_edit',
      data: {
        id,
        sale_type: saleType,
      },
      method: 'post',
      headers: {
        meta: {
          desc: '设备销售类型',
        },
      },
    });
  }

  static getDeviceDetail(
    id = NaN,
  ) {
    return axios({
      url: `/api/product/pv3/${id}/detail`,
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取设备详情',
        },
      },
    });
  }

  static getDistributeHistory(id) {
    return axios({
      url: `/api/product/pv3/${id}/distflow`,
      method: 'get',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取分配流详情',
        },
      },
    });
  }

  static export({ ...data }) {
    return axios({
      url: '/api/product/export',
      method: 'post',
      data: {
        page: data.page,
        limit: data.limit,
        channel_id: data.channel,
        store_id: data.store,
        product_no: data.productNo,
        produce_no: data.produceNo,
        status: data.status,
        name: data.name,
        active_status: data.activeStatus,
        mac_address: data.macAddress,
        product_type: data.productType,
        product_category: data.productCategory,
        active_method: data.activeMethod,
        hardware_version: data.hardwareVersion,
        third_license_status: data.thirdLicenseStatus,
        sn_no: data.snNo,
        batch_no: data.batchNo,
        mes_product_config_id: data.mesProductConfigId,
        train_flag_type: data.trainFlagType,
        exam_flag_type: data.examFlagType,
        sale_type: data.saleType,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }

  static getMesProductConfigList(productType) {
    return axios({
      url: '/api/product/mes-product-config/list',
      data: { product_type: productType },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询渠道列表',
        },
      },
    });
  }
}

export default DeviceManageApi;
