<template>
  <div class="device-manage">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchDevices"
      />
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="permissions[DEVICE_ACTION.CREATE]"
          type="warning"
          @click="() => onDeviceAction(DEVICE_ACTION.CREATE, true)"
        >
          新建设备
        </Button>
      </app-spacing>
      <app-export-bar @onExport="onExport" @onExportAll="onExportAll" />
    </app-block-flex>
    <app-data-table
      :columns="DEVICE_COLUMNS"
      :data="devices"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onPageConfigChange="fetchDevices"
    >
      <template slot-scope="{ row }" slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
      <template slot-scope="{ row }" slot="saleType">
        {{ PRODUCT_SALE_TYPE_TEXT[row.sale_type] }}
      </template>
      <template slot-scope="{ row }" slot="activeStatus">
        {{ ACTIVE_STATUS_TEXT[row.active_status] }}
      </template>
      <template slot-scope="{ row }" slot="productCategory">
        {{ PRODUCT_CATEGORY_TEXT[row.product_category]  }}
      </template>
      <template slot-scope="{ row }" slot="activeMethod">
        {{ ACTIVE_METH_TEXT[row.active_method] }}
      </template>
      <template slot-scope="{ row }" slot="thirdLicenseStatus">
        {{ row.third_license_status }}
      </template>
      <template slot-scope="{ row }" slot="trainFlag">
        {{ convertPadVersionFlag(true, row)   }}
      </template>
      <template slot-scope="{ row }" slot="examFlag">
        {{ convertPadVersionFlag(false, row)  }}
      </template>
      <app-spacing
        spacing="sm"
        class="table-action"
        slot-scope="{ row }"
        slot="action"
      >
        <Button
          v-if="permissions[DEVICE_ACTION.DISTRIBUTE]"
          :disabled="
            row.active_status !== ACTIVE_STATUS.UN_ACTIVE &&
            row.active_status !== ACTIVE_STATUS.RETURN
          "
          type="primary"
          @click="() => onDeviceAction(DEVICE_ACTION.DISTRIBUTE, true, row.id)"
        >
          分配
        </Button>
        <Button
          v-if="permissions[DEVICE_ACTION.DISTRIBUTE_HISTORY]"
          type="primary"
          @click="
            () =>
              onDeviceAction(
                DEVICE_ACTION.DISTRIBUTE_HISTORY,
                true,
                row.id,
                row.product_no,
                row.produce_no
              )
          "
        >
          分配流
        </Button>
        <Button
          v-if="permissions[DEVICE_ACTION.EDIT]"
          type="primary"
          @click="() => onDeviceAction(DEVICE_ACTION.EDIT, true, row.id)"
        >
          编辑
        </Button>
        <Button
          v-if="permissions[DEVICE_ACTION.RETURN]"
          :disabled="row.active_status !== ACTIVE_STATUS.ACTIVE"
          type="error"
          @click="() => onDeviceAction(DEVICE_ACTION.RETURN, true, row.id)"
        >
          退库
        </Button>
        <Button
          v-if="permissions[DEVICE_ACTION.SALE_TYPE_EDIT]"
          type="primary"
          @click="() => onDeviceAction(DEVICE_ACTION.SALE_TYPE_EDIT, true, row.id)"
        >
          销售类型
        </Button>
        <Button
          v-if="permissions[DEVICE_ACTION.REMARK]"
          type="primary"
          @click="() => onDeviceAction(DEVICE_ACTION.REMARK, true, row.id)"
        >
          备注
        </Button>
      </app-spacing>
    </app-data-table>

    <app-modal
      :value="deviceModalConfig.visible"
      :title="deviceActionText"
      @on-ok="() => onModalConfirm()"
      @on-cancel="() => onDeviceAction(null, false)"
    >
      <Timeline
        v-if="deviceModalConfig.action === DEVICE_ACTION.DISTRIBUTE_HISTORY"
      >
        <!-- <h3 class="timeLine">设备编号:{{ productNo }}</h3> -->
        <h3 class="timeLine">生产编号:{{ produceNo }}</h3>
        <TimelineItem
          class="time-line-item"
          v-for="(item, index) in deviceModalConfig.distributeHistory"
          :key="index"
        >
          <span>{{ getDistributionType(item.type) }}</span>
          <span
            >{{ item.group_name }}({{
              getRealUserType(item.opt_group_type)
            }})</span
          >
          <span>{{ item.opt_time }}</span>
        </TimelineItem>
      </Timeline>
      <Form
        v-else
        ref="deviceForm"
        :model="deviceModalData"
        :rules="deviceModalRule"
        :label-width="80"
      >
        <template
          v-if="
            deviceModalConfig.action === DEVICE_ACTION.CREATE ||
            deviceModalConfig.action === DEVICE_ACTION.EDIT
          "
        >
          <FormItem
            label="设备编号"
            v-if="deviceModalConfig.action === DEVICE_ACTION.EDIT"
          >
            {{ deviceModalData.productNo }}
          </FormItem>
          <FormItem label="生产编号" prop="produceNo">
            <Input
              v-model="deviceModalData.produceNo"
              :placeholder="TIPS.PRODUCE"
            />
          </FormItem>
          <FormItem label="设备名称" prop="name">
            <Input v-model="deviceModalData.name" :placeholder="TIPS.NAME" />
          </FormItem>
          <FormItem label="型号" prop="mesProductConfigId">
            <Select v-model="deviceModalData.mesProductConfigId" clearable>
              <Option
                v-for="option in queryConfigMesProductConfigOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="生产批号" prop="batchNo">
            <Input
              v-model="deviceModalData.batchNo"
            />
          </FormItem>
          <FormItem label="硬件版本" prop="hardwareVersion">
            <!-- <Input
              v-model="deviceModalData.hardwareVersion"
              :placeholder="TIPS.HARDWARE_VERSION"
            /> -->
            <Select v-model="deviceModalData.hardwareVersion" :placeholder="TIPS.HARDWARE_VERSION">
              <Option
                v-for="option in deviceModalConfig.hardVersionOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="mac地址" prop="macAddress">
            <Input
              v-model="deviceModalData.macAddress"
              :placeholder="TIPS.MACADDRESS"
            />
          </FormItem>
          <FormItem
            label="设备SN"
            prop="snNo"
            v-if="
            queryData.productType === PRODUCT_TYPE.PV5_SNX && (
            deviceModalConfig.action === DEVICE_ACTION.CREATE ||
            deviceModalConfig.action === DEVICE_ACTION.EDIT
            )
            "
          >
          <Input
              v-model="deviceModalData.snNo"
              :placeholder="TIPS.SN_NO"
            />
          </FormItem>
          <!--          <FormItem-->
          <!--            label="用途"-->
          <!--            prop="purpose">-->
          <!--            <Select-->
          <!--              v-model="deviceModalData.purpose"-->
          <!--              :placeholder="TIPS.PURPOSE">-->
          <!--              <Option-->
          <!--                v-for="option in deviceModalConfig.purposeOptions"-->
          <!--                :value="option.value"-->
          <!--                :key="option.value">-->
          <!--                {{ option.label }}-->
          <!--              </Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <FormItem
            v-if="deviceModalConfig.action === DEVICE_ACTION.EDIT"
            label="运行状态"
            prop="status"
          >
            <Select v-model="deviceModalData.status" :placeholder="TIPS.STATUS">
              <Option
                v-for="option in deviceModalConfig.statusOptions"
                :value="option.value"
                :key="option.value"
              >
                {{ option.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            v-if="deviceModalConfig.action === DEVICE_ACTION.EDIT"
            label="激活状态"
          >
            {{ ACTIVE_STATUS_TEXT[deviceModalData.activeStatus] }}
          </FormItem>
          <FormItem
            label="设备类型"
            prop="productCategory"
          >
            <RadioGroup v-model="deviceModalData.productCategory">
              <Radio v-for="item in queryConfigProductCategoryOptions"
                      :disabled="ACTIVE_STATUS.ACTIVE === deviceModalData.activeStatus"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value">
                <span>{{ item.label }}</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          <span>已激活设备不可修改设备类型</span>
        </template>
        <template
          v-else-if="deviceModalConfig.action === DEVICE_ACTION.DISTRIBUTE"
        >
          <FormItem label="设备名称">
            {{ deviceModalData.name }}
          </FormItem>
          <!-- <FormItem label="设备编号">
            {{ deviceModalData.productNo }}
          </FormItem> -->
          <FormItem label="生产编号">
            {{ deviceModalData.produceNo }}
          </FormItem>
          <div v-if="userType !== 'CHANNEL'">
            <FormItem
              label="分配渠道"
              v-if="userType !== USER_TYPE.OUTLET"
              prop="channelId"
            >
              <Select
                filterable
                v-model="deviceModalData.channelId"
                :placeholder="TIPS.CHANNEL"
                @on-select="(V)=>handleChangeChannel(V.value)"
              >
                <Option
                  v-for="option in deviceModalConfig.channelOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </Option>
              </Select>
            </FormItem>
            <FormItem
              label="分配门店"
              prop="storeId"
            >
              <Select
                v-model="deviceModalData.storeId"
                :placeholder="TIPS.STORE"
                filterable
              >
                <Option
                  v-for="option in deviceModalConfig.storeOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </Option>
              </Select>
            </FormItem>
          </div>
          <FormItem v-else label="分配">
            <app-spacing class="rebind-form-content">
              <Select v-model="config">
                <Option
                  v-for="item in ZHISHU_TYPE_OPTIONS"
                  :value="item.value"
                  :key="item.value"
                >
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                v-if="config === 'provide'"
                v-model="deviceModalData.storeId"
                :placeholder="TIPS.STORE"
                filterable
              >
                <Option
                  v-for="option in deviceModalConfig.storeOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </Option>
              </Select>
              <Select
                filterable
                v-else-if="config === 'channel'"
                v-model="deviceModalData.channelId"
                :placeholder="TIPS.CHANNEL"
              >
                <Option
                  v-for="option in deviceModalConfig.channelOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </Option>
              </Select>
            </app-spacing>
          </FormItem>
        </template>
        <template v-else-if="deviceModalConfig.action === DEVICE_ACTION.RETURN">
          请确认，此设备是否已经退回到仓库，一旦进行此操作，该设备的数据将不再被监控，是否继续操作？
        </template>
        <template v-else-if="deviceModalConfig.action === DEVICE_ACTION.REMARK">
          <Input
            type="textarea"
            v-model="deviceModalData.remarks"
            :rows="8"
            :placeholder="TIPS.REMARK"
          />
        </template>
        <!-- 销售类型编辑 -->
        <template v-else-if="deviceModalConfig.action === DEVICE_ACTION.SALE_TYPE_EDIT">
          <RadioGroup v-model="deviceModalData.saleType">
              <Radio v-for="item in queryConfigSaleTypeOptions"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value">
                <span>{{ item.label }}</span>
              </Radio>
            </RadioGroup>
        </template>
      </Form>
    </app-modal>
  </div>
</template>

<script>
import {
  DEVICE_COLUMNS,
  ALLOWED_PERMISSION,
  DEVICE_ACTION,
  DEVICE_ACTION_TEXT,
  STATUS,
  STATUS_TEXT,
  REVERSE_STATUS_TEXT,
  BASIC_STATUS_OPTIONS,
  STATUS_OPTIONS,
  ACTIVE_STATUS,
  ACTIVE_STATUS_TEXT,
  ACTIVE_STATUS_OPTIONS,
  // PURPOSE_OPTIONS,
  ACTIVE_METH_TEXT,
  TIPS,
  PRODUCT_CATEGORY_TEXT,
  BASIC_HARDVERSION_OPTIONS,
  PRODUCT_PAD_VERSION_FLAG_TYPE_OPTIONS,
  PRODUCT_SALE_TYPE_OPTIONS,
  PRODUCT_SALE_TYPE_TEXT,
} from './constants';
import { USER_TYPE } from '../../login/constants';
import DeviceManageApi from './device-manage.api';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';
import StoreManageApi from '../../business/store-manage/store-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';
import { ZHISHU_TYPE_OPTIONS } from '../../business/store-manage/constants';
import { PRODUCT_CATEGORY, PRODUCT_TYPE } from './enum';

export default {
  name: 'device-manage',
  inject: ['reload'],
  // 切换不同设备类型的菜单，进行页面刷新
  watch: {
    $route() {
      if (this.$route.meta) {
        this.reload();
      }
    },
  },
  computed: {
    deviceActionText() {
      return DEVICE_ACTION_TEXT[this.deviceModalConfig.action];
    },
    userType() {
      return this.$store.getters.userType.toUpperCase();
    },
    queryConfig() {
      const queryList = [
        {
          fieldName: 'channel',
          name: '归属渠道',
          show: this.userType === 'OUTLET',
          type: 'select',
          options: this.queryConfigChannelOptions,
        },
        {
          fieldName: 'store',
          name: '归属门店',
          type: 'select',
          options: this.queryConfigStoreOptions,
        },
        {
          fieldName: 'productNo',
          name: '设备编号',
        },
        {
          fieldName: 'produceNo',
          name: '生产编号',
        },
        {
          fieldName: 'status',
          name: '运行状态',
          type: 'select',
          options: STATUS_OPTIONS,
        },
        {
          fieldName: 'activeStatus',
          name: '激活状态',
          type: 'select',
          options: ACTIVE_STATUS_OPTIONS,
        },
        {
          fieldName: 'macAddress',
          name: 'mac地址',
        },
        {
          fieldName: 'name',
          name: '设备名',
        },
        {
          fieldName: 'productCategory',
          name: '设备类型',
          type: 'select',
          options: this.queryConfigProductCategoryOptions,
        },
        {
          fieldName: 'activeMethod',
          name: '激活方式',
          type: 'select',
          options: this.queryConfigProductActivaMethodOptions,
        },
        {
          fieldName: 'hardwareVersion',
          name: '硬件版本',
          type: 'select',
          options: this.deviceModalConfig.hardVersionOptions,
        },
        {
          fieldName: 'batchNo',
          name: '生产批号',
        },
        {
          fieldName: 'mesProductConfigId',
          name: '型号',
          type: 'select',
          options: this.queryConfigMesProductConfigOptions,
        },
        {
          fieldName: 'trainFlagType',
          name: '训练模块',
          type: 'select',
          options: PRODUCT_PAD_VERSION_FLAG_TYPE_OPTIONS,
        },
        {
          fieldName: 'examFlagType',
          name: '检查模块',
          type: 'select',
          options: PRODUCT_PAD_VERSION_FLAG_TYPE_OPTIONS,
        },
        {
          fieldName: 'saleType',
          name: '销售类型',
          type: 'select',
          options: PRODUCT_SALE_TYPE_OPTIONS,
        },
      ];
      if (this.queryData.productType === PRODUCT_TYPE.PV5_SNX) {
        queryList.push({
          fieldName: 'snNo',
          name: '设备SN',
        });
        queryList.push({
          fieldName: 'thirdLicenseStatus',
          name: '学习授权',
          type: 'select',
          options: this.queryConfigProductThirdLicenseOptions,
        });
      }
      return queryList;
    },
    DEVICE_COLUMNS() {
      const allowedPermissionValues = Object.values(ALLOWED_PERMISSION);
      // eslint-disable-next-line max-len
      const count = Object.keys(this.permissions).filter((k) => allowedPermissionValues.includes(k)).length;
      return DEVICE_COLUMNS.filter((item) => (this.queryData.productType === PRODUCT_TYPE.PV5_SNX || (item.key !== 'sn_no' && item.key !== 'third_license_status'))).map((c) => {
        if (c.slot === 'action') {
          // eslint-disable-next-line no-param-reassign
          c.width = count * 80;
        }
        return c;
      });
    },
  },
  data() {
    return {
      PRODUCT_TYPE,
      USER_TYPE,
      ALLOWED_PERMISSION,
      DEVICE_ACTION,
      STATUS,
      STATUS_TEXT,
      REVERSE_STATUS_TEXT,
      ACTIVE_STATUS,
      ACTIVE_STATUS_TEXT,
      ACTIVE_METH_TEXT,
      TIPS,
      ZHISHU_TYPE_OPTIONS,
      PRODUCT_CATEGORY_TEXT,
      PRODUCT_SALE_TYPE_TEXT,
      config: 'provide',
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        channel: '',
        store: '',
        productNo: '',
        produceNo: '',
        status: STATUS.ALL,
        activeStatus: ACTIVE_STATUS.ALL,
        name: '',
        productType: this.$route.meta.type,
        activeMethod: '',
        hardwareVersion: '',
        thirdLicenseStatus: null,
        snNo: '',
        batchNo: '',
        mesProductConfigId: null,
        trainFlagType: null,
        examFlagType: null,
        saleType: null,
      },
      devices: [],
      deviceModalConfig: {
        visible: false,
        action: null,
        hardVersionOptions: BASIC_HARDVERSION_OPTIONS,
        statusOptions: BASIC_STATUS_OPTIONS,
        // purposeOptions: PURPOSE_OPTIONS,
        channelOptions: [],
        storeOptions: [],
        distributeHistory: [],
      },
      productNo: '',
      produceNo: '',
      deviceModalData: {
        id: NaN,
        name: '',
        productNo: '',
        produceNo: '',
        hardwareVersion: '',
        macAddress: '',
        // purpose: '',
        status: STATUS.ENABLE,
        activeStatus: ACTIVE_STATUS.UN_ACTIVE,
        channelId: '',
        storeId: '',
        remarks: '',
        productType: this.$route.meta.type,
        productCategory: PRODUCT_CATEGORY.ALL,
      },
      deviceModalRule: {
        produceNo: [
          { required: true, message: TIPS.PRODUCE, trigger: 'change' },
        ],
        hardwareVersion: [
          { required: true, message: TIPS.HARDWARE_VERSION, trigger: 'change' },
        ],
        macAddress: [
          { required: true, message: TIPS.MACADDRESS, trigger: 'change' },
        ],
        name: [{ required: true, message: TIPS.NAME, trigger: 'change' }],
        status: [
          {
            required: true,
            message: TIPS.STATUS,
            trigger: 'change',
            type: 'number',
          },
        ],
        channelId: [
          {
            required: true,
            message: TIPS.CHANNEL,
            trigger: 'change',
            type: 'number',
          },
        ],
        storeId: [
          {
            required: true,
            message: TIPS.STORE,
            trigger: 'change',
            type: 'number',
          },
        ],
        remarks: [{ required: false, message: TIPS.REMARK, trigger: 'change' }],
        productCategory: [{
          required: true, message: TIPS.PRODUCT_CATEGORY, trigger: 'change', type: 'number',
        }],
        snNo: [
          { required: true, message: TIPS.SN_NO, trigger: 'change' },
        ],
      },
      queryConfigChannelOptions: [],
      queryConfigStoreOptions: [],
      queryConfigProductCategoryOptions: [],
      queryConfigProductActivaMethodOptions: [
        { label: '门店', value: 'store' },
        { label: '消费者', value: 'customer' },
      ],
      queryConfigProductThirdLicenseOptions: [
        { label: '未授权', value: 1 },
        { label: '已授权', value: 2 },
        { label: '取消授权', value: 4 },
        { label: '已过期', value: 5 },
      ],
      queryConfigMesProductConfigOptions: [],
      queryConfigSaleTypeOptions: [
        { label: '出售', value: 1 },
        { label: '租赁', value: 2 },
        { label: '试用', value: 3 },
      ],
    };
  },
  created() {
    this.fetchDevices();
    this.fetchModelChannelOptions();
    this.fetchQueryStoreOptions();
    this.fetchQueryMesProductConfigOptions();
    if (this.userType !== USER_TYPE.ADMIN) {
      this.fetchModelStoreOptions();
    }
    if (this.userType !== 'OUTLET') {
      this.fetchQueryChannelOptions();
    }

    this.fetchQueryProductCategoryOptions();

    if (this.userType === USER_TYPE.ADMIN) {
      this.deviceModalRule.storeId = [
        {
          required: false,
          message: TIPS.STORE,
          trigger: 'change',
          type: 'number',
        },
      ];
    }
  },
  methods: {
    fetchQueryProductCategoryOptions() {
      this.queryConfigProductCategoryOptions.push({
        label: PRODUCT_CATEGORY_TEXT[PRODUCT_CATEGORY.HOME],
        value: PRODUCT_CATEGORY.HOME,
      });
      this.queryConfigProductCategoryOptions.push({
        label: PRODUCT_CATEGORY_TEXT[PRODUCT_CATEGORY.STORE],
        value: PRODUCT_CATEGORY.STORE,
      });
      this.queryConfigProductCategoryOptions.push({
        label: PRODUCT_CATEGORY_TEXT[PRODUCT_CATEGORY.ALL],
        value: PRODUCT_CATEGORY.ALL,
      });
    },
    getRealUserType(type) {
      let name = '';

      switch (type) {
        case 'sys':
          name = '运营';
          break;
        case 'channel':
          name = '渠道';
          break;
        case 'store':
          name = '门店';
          break;
        case 'customer':
          name = '消费者';
          break;
        default:
          break;
      }

      return name;
    },
    getDistributionType(type) {
      let name = '';

      switch (type) {
        case 1:
          name = '分配';
          break;
        case 2:
          name = '激活';
          break;
        case 3:
          name = '退库';
          break;
        default:
          break;
      }

      return name;
    },
    async fetchDevices() {
      const {
        page,
        limit,
        channel,
        store,
        productNo,
        produceNo,
        status,
        activeStatus,
        macAddress,
        name,
        productType,
        productCategory,
        activeMethod,
        hardwareVersion,
        thirdLicenseStatus,
        snNo,
        batchNo,
        mesProductConfigId,
        trainFlagType,
        examFlagType,
        saleType,
      } = this.queryData;
      const { count = 0, results = [] } = await DeviceManageApi.getDevices(
        page,
        limit,
        channel?.toString(),
        store,
        productNo,
        produceNo,
        status,
        activeStatus,
        macAddress,
        name,
        productType,
        productCategory,
        activeMethod,
        hardwareVersion,
        thirdLicenseStatus,
        snNo,
        batchNo,
        mesProductConfigId,
        trainFlagType,
        examFlagType,
        saleType,
      );
      this.devices = results;
      this.$set(this.queryData, 'count', count);
    },
    async fetchQueryChannelOptions() {
      const options = await ChannelManageApi.getChannelsByChannelName();
      this.queryConfigChannelOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async fetchQueryStoreOptions() {
      const options = await StoreManageApi.getStoresByStoreNameOrChannelId();
      this.queryConfigStoreOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async fetchModelChannelOptions() {
      const channelOptions = await ChannelManageApi.getSubChannelOfCurrentUser();
      this.$set(this.deviceModalConfig, 'channelOptions', channelOptions);
    },
    async fetchModelStoreOptions(channelId) {
      let storeOptions;
      if (this.userType !== USER_TYPE.ADMIN) {
        storeOptions = await StoreManageApi.getSubStoreOfCurrentUser();
      } else {
        if (!channelId) {
          return;
        }
        storeOptions = await StoreManageApi.getStoresByStoreNameOrChannelId('', channelId);
      }
      this.$set(this.deviceModalConfig, 'storeOptions', storeOptions);
    },
    async fetchQueryMesProductConfigOptions() {
      const options = await DeviceManageApi.getMesProductConfigList(this.queryData.productType);
      this.queryConfigMesProductConfigOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async fetchDeviceDetail(deviceId = NaN) {
      const {
        id,
        name,
        product_no: productNo,
        produce_no: produceNo,
        hardware_version: hardwareVersion,
        status,
        remarks,
        channel_id: channelId, // TODO: 分配时没有渠道和门店ID
        mac_address: macAddress,
        store_id: storeId,
        active_status: activeStatus,
        product_category: productCategory,
        sn_no: snNo,
        batch_no: batchNo,
        mes_product_config_id: mesProductConfigId,
        sale_type: saleType,
        // purpose,
      } = await DeviceManageApi.getDeviceDetail(deviceId);
      this.deviceModalData = {
        ...this.deviceModalData,
        id,
        name,
        productNo,
        produceNo,
        hardwareVersion,
        status,
        remarks,
        channelId,
        storeId,
        macAddress,
        activeStatus,
        productCategory,
        // purpose,
        snNo,
        batchNo,
        mesProductConfigId,
        saleType,
      };
    },
    async fetchDistributeHistory(deviceId = NaN) {
      const distributeHistory = await DeviceManageApi.getDistributeHistory(
        deviceId,
      );
      this.$set(
        this.deviceModalConfig,
        'distributeHistory',
        distributeHistory || [],
      );
    },
    async onDeviceAction(
      action = DEVICE_ACTION.CREATE,
      visible = false,
      deviceId = NaN,
      productNo,
      produceNo,
    ) {
      if (visible) {
        this.$refs.deviceForm.resetFields();
        if (this.userType === USER_TYPE.ADMIN) {
          this.$set(this.deviceModalConfig, 'storeOptions', []);
        }
      }

      if (
        action === DEVICE_ACTION.EDIT
        || action === DEVICE_ACTION.DISTRIBUTE
        || action === DEVICE_ACTION.RETURN
        || action === DEVICE_ACTION.REMARK
        || action === DEVICE_ACTION.SALE_TYPE_EDIT
      ) {
        await this.fetchDeviceDetail(deviceId);
      } else if (action === DEVICE_ACTION.DISTRIBUTE_HISTORY) {
        this.productNo = productNo;
        this.produceNo = produceNo;
        await this.fetchDistributeHistory(deviceId);
      } else {
        this.deviceModalData = {
          id: deviceId,
          name: '',
          productNo: '',
          produceNo: '',
          remarks: '',
          hardwareVersion: '',
          // purpose: '',
          macAddress: '',
          status: STATUS.ENABLE,
          activeStatus: ACTIVE_STATUS.UN_ACTIVE,
          channelId: '',
          storeId: '',
          productType: this.$route.meta.type,
          productCategory: PRODUCT_CATEGORY.ALL,
        };
      }

      this.deviceModalConfig = {
        ...this.deviceModalConfig,
        visible,
        action,
      };
    },
    async onModalConfirm() {
      if (this.deviceModalConfig.action === DEVICE_ACTION.DISTRIBUTE_HISTORY) {
        await this.onDeviceAction(null);
        return;
      }

      this.$refs.deviceForm.validate(async (valid) => {
        if (!valid) {
          return;
        }

        const {
          id,
          name,
          channelId,
          productNo,
          produceNo,
          remarks,
          storeId,
          hardwareVersion,
          status,
          macAddress,
          productType,
          productCategory,
          snNo,
          batchNo,
          mesProductConfigId,
          saleType,
        } = this.deviceModalData;

        switch (this.deviceModalConfig.action) {
          case DEVICE_ACTION.CREATE:
            await DeviceManageApi.createDevice(
              name,
              hardwareVersion,
              productNo,
              produceNo,
              null,
              null,
              macAddress,
              productType,
              productCategory,
              snNo,
              batchNo,
              mesProductConfigId,
            );
            break;
          case DEVICE_ACTION.EDIT:
            await DeviceManageApi.editDevice(
              id,
              name,
              hardwareVersion,
              productNo,
              produceNo,
              null,
              status,
              macAddress,
              productCategory,
              snNo,
              batchNo,
              mesProductConfigId,
            );
            break;
          case DEVICE_ACTION.RETURN:
            await DeviceManageApi.returnDevice(id);
            break;
          case DEVICE_ACTION.DISTRIBUTE:
            await DeviceManageApi.distributeDevice(
              id,
              channelId,
              // this.userType !== USER_TYPE.ADMIN ? storeId : NaN,
              storeId,
            );
            break;
          case DEVICE_ACTION.REMARK:
            await DeviceManageApi.remarkDevice(id, remarks);
            break;
          case DEVICE_ACTION.SALE_TYPE_EDIT:
            await DeviceManageApi.saleTypeDevice(id, saleType);
            break;
          default:
            break;
        }

        await this.onDeviceAction(null);
        await this.fetchDevices();
      });
    },
    async onExport() {
      const key = await DeviceManageApi.export(
        { ...this.queryData, productType: this.$route.meta.type },
      );
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async onExportAll() {
      const key = await DeviceManageApi.export({ productType: this.$route.meta.type });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },

    handleChangeChannel(channelId) {
      this.deviceModalData.storeId = null;
      this.fetchModelStoreOptions(channelId);
    },

    convertPadVersionFlag(isTrain, row) {
      let type = null;
      let endTime = null;
      if (isTrain) {
        type = row.tt_ppv_type;
        endTime = row.tt_ppv_end_time;
      } else {
        type = row.te_ppv_type;
        endTime = row.te_ppv_end_time;
      }

      if (type === null) {
        return '关闭';
      }

      if (endTime && new Date(endTime) < new Date()) {
        return '过期';
      }
      return '开通';
    },
  },
};
</script>

<style scoped>
.time-line-item .ivu-timeline-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timeLine {
  margin-bottom: 10px;
}
</style>
